<template>
  <div class="main-body">
    <div class="flex-wrap">
      <div class="service-left" id="service-left">
        <div
          class="card"
          v-for="(items, index) in getProcessDataByBusinessPlate"
          :key="index"
        >
          <div>
            <div class="horn-title-style">
              {{ dictOptions.length > 0 ? dictOptions[index]['text'] : '' }}
            </div>
          </div>
          <desformCard :grids="items" @handClick="handClick" />
        </div>
      </div>
      <div class="flex-con service-right">
        <div class="card">
          <div class="flex-wrap flex-h-center flex-between">
            <div class="horn-title-style">服务单位</div>
            <div
              class="more-btn"
              @click="
                $router.push({
                  name: 'Resources',
                  query: { tabIndex: 0 },
                })
              "
            >
              更多>>
            </div>
          </div>
          <div>
            <div class="example" v-if="loading">
              <a-spin size="large" />
            </div>
            <ul class="flex-wrap flex-wrap-wrap flex-between" v-else>
              <li
                v-for="(item, index) in mechanismList"
                :key="index"
                @click="openMechanismDetails(item)"
              >
                <img :src="item.pic" alt="" width="186" height="119" />
                <div class="name t-hide">{{ item.name }}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { activitiListAll, ajaxGetDictItems, yqmsAgencyList } from '@/api/api'
import DesformCard from './DesformCard'
export default {
  components: {
    DesformCard,
  },
  data() {
    return {
      processData: [],
      dictOptions: [],
      loading: false,
      mechanismList: [],
    }
  },
  computed: {
    getProcessDataByBusinessPlate() {
      var list = {}
      this.processData.forEach(function (item) {
        if (!list[item.businessPlate]) {
          list[item.businessPlate] = []
        }
        list[item.businessPlate].push(item)
      })
      return list
    },
    ...mapState({
      authMessage: (state) => state.auth.authMessage,
      authInfo: (state) => state.auth.authInfo,
      token: (state) => state.user.token,
      role: (state) => state.user.role,
    }),
  },
  created() {
    this.filterDictTextByCache()
    this.getList()
    this.getMechanismList()
  },
  mounted() {
    if (this.$route.params.index) {
      this.$nextTick(() => {
        document.querySelector('#service-left').scrollIntoView(true)
      })
    }
  },
  activated() {
    this.filterDictTextByCache()
    this.getList()
    this.getMechanismList()
    if (this.$route.params.index) {
      this.$nextTick(() => {
        document.querySelector('#service-left').scrollIntoView(true)
      })
    }
  },
  methods: {
    getList() {
      activitiListAll({ isShow: true })
        .then((res) => {
          if (res.success) {
            // console.log(res)
            this.processData = res.result
          } else {
            this.$message.warning(res.message)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    filterDictTextByCache() {
      ajaxGetDictItems('business_sector', null).then((res) => {
        if (res.success) {
          this.dictOptions = res.result
        }
      })
    },
    handClick(data) {
      if (this.token) {
        let flag = false
        this.role.forEach((item) => {
          if (data.processRoles.indexOf(item.id) != -1) {
            flag = true
            return
          }
        })
        if (flag) {
          if (data.tableName) {
            this.$router.push({
              name: 'ConferenceRoom',
              params: {
                data: window.btoa(encodeURIComponent(JSON.stringify(data))),
              },
            })
          } else {
            this.$router.push({
              name: 'ApplyService',
              params: {
                data: window.btoa(encodeURIComponent(JSON.stringify(data))),
              },
            })
          }
        } else {
          this.$auth(this.authMessage, this.authInfo)
        }
      } else {
        this.$message.warning('未登录，请先登录')
        this.$login()
      }
    },

    async getMechanismList() {
      this.loading = true
      try {
        const params = {
          pageNo: 1,
          pageSize: 6,
        }
        const res = await yqmsAgencyList(params)
        if (res.success) {
          this.mechanismList = res.result.records
          this.loading = false
        } else {
          this.loading = false
          this.$message.error(res.message)
        }
      } catch (err) {
        this.loading = false
        this.$message.error(err.message)
      }
    },

    openMechanismDetails(data) {
      this.$router.push({
        name: 'MechanismDetails',
        params: {
          data: window.btoa(encodeURIComponent(JSON.stringify(data))),
        },
      })
    },
  },
}
</script>
<style lang="less" scoped>
.main-body {
  background: #f4f4f4;
  font-family: Source Han Sans CN;
  > div {
    width: 1200px;
    margin: 0 auto;
    padding: 50px 0;
    .service-left {
      width: 737px;
      .card {
        > div {
          &:nth-child(1) {
            border-bottom: 1px solid #e5e5e5;
          }
        }
      }
    }

    .service-right {
      margin-left: 24px;
      .card {
        width: 100%;
        padding: 20px 10px 0 10px;

        > div {
          &:nth-child(1) {
            border-bottom: 1px solid #e5e5e5;
            margin: 0 10px;
          }
          &:nth-child(2) {
            padding-top: 45px;
          }
        }

        li {
          width: 50%;
          cursor: pointer;
          padding: 0 10px;
          .name {
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            margin-top: 15px;
            margin-bottom: 20px;
          }
        }
      }

      .example {
        text-align: center;
        padding: 100px 0;
      }
    }
  }
}

.card {
  background: #fff;
  padding: 20px 24px 0 24px;
  margin-bottom: 24px;
}

.horn-title-style {
  width: 160px;
  height: 55px;
  line-height: 55px;
  background-color: #1285d6;
  position: relative;
  font-size: 22px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;

  &::before {
    content: '';
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: rgb(18, 133, 214);
    top: 100%;
    left: 50%;
    position: absolute;
    margin-left: -12px;
  }
}

.more-btn {
  border: 1px solid #1285d6;
  font-size: 16px;
  font-weight: 400;
  color: #1285d6;
  padding: 0 7px;
  cursor: pointer;
}
</style>
