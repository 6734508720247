<template>
  <div class="main-body">
    <div class="content">
      <div class="title flex-wrap flex-h-center">
        <div
          :class="['horn-title-style', tabIndex === 0 ? 'active' : '']"
          @click="changeTab(0)"
        >
          {{ applyData.name }}
        </div>
        <div
          :class="['horn-title-style', tabIndex === 1 ? 'active' : '']"
          @click="changeTab(1)"
        >
          介绍
        </div>
      </div>
      <div class="enclosure flex-wrap flex-h-center" v-if="enclosureData.list">
        <div>下载附件：</div>
        <div>
          <ul class="flex-wrap flex-h-center">
            <li
              v-for="(item, index) in this.enclosureData.list"
              :key="index"
              @click="downEnclosure(item.url)"
            >
              <div>{{ item.name }}</div>
              <div>{{ (item.size / 1024).toFixed(2) }}KB</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-show="tabIndex === 0">
      <div class="form-content">
        <k-form-build ref="kfb" :value="kfdJsonData" />
      </div>
      <div class="footer">
        <a-button
          :loading="loading"
          class="btn-style"
          type="primary"
          @click="desformApplyHandleOk"
          >确定</a-button
        >
        <a-button class="btn-style" @click="$router.go(-1)"> 取消 </a-button>
      </div>
    </div>
    <div v-show="tabIndex === 1" class="description">
      <div v-html="applyData.description"></div>
    </div>
  </div>
</template>
<script>
import { activitiListAll, ajaxGetDictItems } from '@/api/api'
import {
  saveOrUpdate,
  getFormByProcessDefinitionId,
  startInstance,
} from '@/api/process'
import { KFormBuild } from '@/components/k-form-design/index'
export default {
  components: {
    KFormBuild,
  },
  data() {
    return {
      applyData: {},
      kfdJsonData: {},
      formId: '',
      tabIndex: 0,
      loading: false,
      enclosureData: {},
    }
  },
  created() {
    if (this.$route.params.data) {
      this.applyData = JSON.parse(
        decodeURIComponent(window.atob(this.$route.params.data))
      )
      this.enclosureData = JSON.parse(this.applyData.annexJson)
      this.getProcessData()
    }
  },
  methods: {
    getList() {
      activitiListAll()
        .then((res) => {
          if (res.success) {
            this.processData = res.result
          } else {
            this.$message.warning(res.message)
          }
        })
        .catch((err) => {
          this.$message.warning(err.message)
        })
    },
    filterDictTextByCache() {
      ajaxGetDictItems('business_sector', null).then((res) => {
        if (res.success) {
          this.dictOptions = res.result
        }
      })
    },
    getProcessData() {
      const pa = {
        modelId: this.applyData.id,
        processDefinitionId: this.applyData.processDefinitionId,
      }
      // 根据modelId请求流程最新的发布数据
      getFormByProcessDefinitionId(pa).then((res) => {
        if (res.success) {
          if (!res.result) {
            return this.$message.warning('该工单未设计表单')
          }
          this.formId = res.result.id
          this.kfdJsonData = JSON.parse(res.result.htmlJson)
          console.log(this.kfdJsonData)
        } else {
          this.$message.warning(res.message)
        }
      })
    },

    async desformApplyHandleOk() {
      // this.loading = true
      try {
        const formData = await this.$refs.kfb.getData()
        console.log(formData)
        const res = await saveOrUpdate({
          modelId: this.applyData.id,
          formId: this.formId,
          processDefinitionId: this.applyData.processDefinitionId,
          formData: JSON.stringify(formData),
          type: 2,
        })
        if (res.success) {
          // this.startInstance(res.result.id)
          this.loading = false
          this.$message.success(res.message)
          this.$router.go(-1)
        } else {
          this.loading = false
          this.$message.warning(res.message)
        }
      } catch (err) {
        this.loading = false
        this.$message.warning(err.message)
      }
    },

    // startInstance(id) {
    //   const params = {
    //     modelId: this.applyData.id,
    //     formDataId: id,
    //     processDefinitionId: this.applyData.processDefinitionId,
    //   }
    //   startInstance(params).then((res) => {
    //     if (res.success) {
    //       this.loading = false
    //       this.$message.success(res.message)
    //       this.$router.go(-1)
    //     } else {
    //       this.loading = false
    //       this.$message.warning(res.message)
    //     }
    //   })
    // },

    changeTab(index) {
      this.tabIndex = index
    },

    downEnclosure(url) {
      window.location.href = url
    },
  },
}
</script>
<style lang="less" scoped>
.main-body {
  background: #f1f1f1;
  padding: 50px 0;
  .content {
    width: 1200px;
    background: #fff;
    margin: auto;

    .title {
      border-bottom: 1px solid #e5e5e5;
      .horn-title-style {
        cursor: pointer;
        padding: 0 30px;
        height: 55px;
        line-height: 55px;
        position: relative;
        font-size: 22px;
        font-weight: 400;
        color: #333;
        text-align: center;

        &::before {
          content: '';
          width: 0;
          height: 0;
          border: 10px solid transparent;
          top: 100%;
          left: 50%;
          position: absolute;
          margin-left: -12px;
        }
      }

      .active {
        background-color: #1285d6;
        color: #fff;
        &::before {
          border-top-color: rgb(18, 133, 214);
        }
      }
    }

    .enclosure {
      padding: 20px 50px;
      ul {
        margin: 0;
        li {
          border: 1px solid #e5e5e5;
          padding: 10px;
          cursor: pointer;
          margin: 10px;
        }
      }
    }
  }

  .form-content {
    padding: 30px 50px;
    width: 1200px;
    min-height: 600px;
    background: #fff;
    margin: auto;
  }

  .footer {
    width: 1200px;
    margin: 0 auto;
    margin-top: 25px;
    background: #fff;
    text-align: center;
    padding: 20px 0;

    .btn-style {
      height: 40px;
      line-height: 40px;
      padding: 0 30px;
      margin: 0 50px;
    }
  }

  .description {
    width: 1200px;
    min-height: 600px;
    background: #fff;
    margin: 0 auto;
    padding: 30px;
  }
}
</style>
