<template>
  <div class="main-body">
    <div class="content">
      <div class="title flex-wrap">
        <div
          :class="['horn-title-style', tabIndex === 0 ? 'active' : '']"
          @click="changeTab(0)"
        >
          会议室申请
        </div>
        <div
          :class="['horn-title-style', tabIndex === 1 ? 'active' : '']"
          @click="changeTab(1)"
        >
          介绍
        </div>
      </div>
    </div>
    <div v-show="tabIndex === 0">
      <div class="form-content">
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="会议室" prop="roomId">
            <a-select
              v-model="form.roomId"
              placeholder="请选择会议室"
              @change="changeRoom"
            >
              <a-select-option v-for="item in roomList" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="会议日期" prop="useDate">
            <a-date-picker
              placeholder="请选择会议日期"
              format="YYYY-MM-DD"
              v-model="dateTime"
              @change="changeDate"
              :disabled="roomDisabled"
              :disabledDate="disabledDate"
            />
          </a-form-model-item>

          <a-form-model-item
            label="会议时间段"
            prop="usedTimes"
            class="tips"
            v-show="dateTime"
          >
            <div class="flex-wrap flex-wrap-wrap">
              <div
                :class="[
                  'item',
                  itemIndex.indexOf(index) > -1 ? 'active' : '',
                  selectedTime.indexOf(index) > -1 ? 'time-disable' : '',
                ]"
                v-for="(item, index) in dataTime"
                :key="index"
                @click="changeItem(index)"
              >
                {{ item.time }}
              </div>
            </div>
          </a-form-model-item>

          <a-form-model-item label="会议人数" prop="num">
            <a-input-number
              :min="1"
              :step="1"
              placeholder="请输入会议人数"
              v-model="form.num"
              style="width: 100%"
            />
          </a-form-model-item>

          <a-form-model-item label="备注">
            <a-textarea
              v-model="form.remark"
              placeholder="请输入备注"
              :rows="4"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="footer">
        <a-button
          :loading="loading"
          class="btn-style"
          type="primary"
          @click="desformApplyHandleOk"
          >确定</a-button
        >
        <a-button class="btn-style" @click="$router.go(-1)"> 取消 </a-button>
      </div>
    </div>
    <div v-show="tabIndex === 1" class="description">
      <div v-html="applyData.description"></div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import {
  getUsedTimesByDate,
  queryByTableNames,
  yqmsMeetRoomListNotPage,
} from '@/api/api'
import { postAction, getAction } from '@/api/manage'
import { mapGetters } from 'vuex'
export default {
  data() {
    let validateTime = (rule, value, callback) => {
      if (this.itemIndex.length <= 0) {
        callback(new Error('请选择会议时间段'))
      } else {
        callback()
      }
    }
    return {
      dataTime: [
        {
          key: 0,
          time: '08:00-08:30',
        },
        {
          key: 1,
          time: '08:30-09:00',
        },
        {
          key: 2,
          time: '09:00-09:30',
        },
        {
          key: 3,
          time: '10:00-10:30',
        },
        {
          key: 4,
          time: '10:30-11:00',
        },
        {
          key: 5,
          time: '11:00-11:30',
        },
        {
          key: 6,
          time: '11:30-12:00',
        },
        {
          key: 7,
          time: '12:00-12:30',
        },
        {
          key: 8,
          time: '12:30-13:00',
        },
        {
          key: 9,
          time: '13:00-13:30',
        },
        {
          key: 10,
          time: '13:30-14:00',
        },
        {
          key: 11,
          time: '14:00-14:30',
        },
        {
          key: 12,
          time: '14:30-15:00',
        },
        {
          key: 13,
          time: '15:00-15:30',
        },
        {
          key: 14,
          time: '15:30-16:00',
        },
        {
          key: 15,
          time: '16:00-16:30',
        },
        {
          key: 16,
          time: '16:30-17:00',
        },
        {
          key: 17,
          time: '17:00-17:30',
        },
        {
          key: 18,
          time: '17:30-18:00',
        },
      ],
      loading: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 14 },
      form: {
        roomId: undefined,
        useDate: '',
        usedTimes: '',
        remark: '',
        num: undefined,
      },
      rules: {
        roomId: [
          {
            required: true,
            message: '请选择会议室',
            trigger: 'change',
          },
        ],
        useDate: [
          {
            required: true,
            message: '请选择会议日期',
            trigger: 'change',
          },
        ],
        usedTimes: [{ validator: validateTime, trigger: 'change' }],
        num: [
          {
            required: true,
            message: '请输入会议人数',
            trigger: 'blue',
          },
        ],
      },
      roomList: [],
      roomDisabled: true,
      dateTime: undefined,
      itemIndex: [],
      selectedTime: [],
      url: {
        optPre: '/online/cgform/api/form/',
        startProcess: '/activiti/instance/startOnlInstance',
      },
      tableId: '',
      tabIndex: 0,
      applyData: {},
    }
  },
  created() {
    if (this.$route.params.data) {
      this.applyData = JSON.parse(
        decodeURIComponent(window.atob(this.$route.params.data))
      )
      // console.log(new Date().toLocaleDateString())
      // this.dateTime = moment(new Date().toLocaleDateString(), 'YYYY-MM-DD')
    }
    this.getRoomList()
    this.getTableId()
  },
  methods: {
    moment,
    ...mapGetters(['userInfo']),
    // 获取全部会议室
    async getRoomList() {
      try {
        const params = {}
        const res = await yqmsMeetRoomListNotPage(params)
        if (res.success) {
          this.roomList = res.result
        } else {
          this.$message.error(res.message)
        }
      } catch (err) {
        this.$message.error(err.message)
      }
    },

    changeRoom() {
      this.roomDisabled = false
    },
    disabledDate(current) {
      return current < moment().subtract(1, 'day') //今天之前的年月日不可选，不包括今天
    },

    // 选择日期获取当天会议室被占用的时间段
    changeDate(date, dateString) {
      this.selectedTime = []
      if (dateString) {
        this.form.useDate = dateString
        this.getUsedTimesByDate(dateString)
      } else {
        this.form.useDate = ''
      }
    },
    getUsedTimesByDate(dateString) {
      const params = {
        id: this.form.roomId,
        date: dateString,
      }

      getUsedTimesByDate(params)
        .then((res) => {
          if (res.success) {
            this.setSelectedTime(res.result)
          } else {
            this.$message.error(res.message)
          }
        })
        .catch((err) => {
          this.$message.error(err.message)
        })
    },
    setSelectedTime(result) {
      if (result.length > 0) {
        let selectedList = this.dataTime.filter((obj) =>
          result.some((obj1) => obj1 == obj.time)
        )
        selectedList.forEach((item) => {
          this.selectedTime.push(item.key)
        })
      }
    },

    // 选择时间段逻辑
    changeItem(index) {
      let selectIndex = this.selectedTime.indexOf(index)
      if (selectIndex > -1) {
        return
      }

      let arrIndex = this.itemIndex.indexOf(index)
      if (arrIndex > -1) {
        if (this.itemIndex.length > 2) {
          if (
            index - this.itemIndex[0] > 0 &&
            index != this.itemIndex[this.itemIndex.length - 1]
          ) {
            let findIndex = this.itemIndex.findIndex((item) => item === index)
            this.itemIndex.splice(findIndex, this.itemIndex.length - findIndex)
          } else {
            let aIndex = this.itemIndex.findIndex((item) => item === index)
            this.itemIndex.splice(aIndex, 1)
          }
        } else {
          this.itemIndex.splice(arrIndex, 1)
        }
      } else {
        this.itemIndex.push(index)
        this.itemIndex.sort(function (a, b) {
          return a - b
        })
        if (this.itemIndex.length >= 2) {
          let flag = this.judgmentContinuity(this.itemIndex)
          if (flag) {
            let flagIndex = this.itemIndex.findIndex((item) => item === index)
            this.itemIndex.splice(flagIndex, 1)
            return
          }
          let difference =
            this.itemIndex[this.itemIndex.length - 1] - this.itemIndex[0]
          if (difference != 1) {
            let newarr = []
            for (let index = 0; index < difference - 1; index++) {
              newarr.push(this.itemIndex[0] + 1 + index)
            }

            this.itemIndex = this.itemIndex
              .concat(newarr)
              .sort(function (a, b) {
                return a - b
              })

            this.itemIndex = [...new Set(this.itemIndex)]
          }

          if (
            index - this.itemIndex[0] > 0 &&
            index != this.itemIndex[this.itemIndex.length - 1]
          ) {
            let findIndexs = this.itemIndex.findIndex((item) => item === index)
            this.itemIndex.splice(
              findIndexs,
              this.itemIndex.length - findIndexs
            )
          }
        }
      }
    },
    judgmentContinuity(list) {
      let difference = list[this.itemIndex.length - 1] - list[0]
      let newarr = []
      for (let index = 0; index < difference + 1; index++) {
        newarr.push(list[0] + index)
      }
      let selectedList = this.selectedTime.filter((obj) =>
        newarr.some((obj1) => obj1 == obj)
      )
      if (selectedList.length > 0) {
        return true
      } else {
        return false
      }
    },

    // 获取会议室申请tableId
    getTableId() {
      const params = {
        tableNames: 'yqms_meetingroom_applyfor',
      }

      queryByTableNames(params)
        .then((res) => {
          if (res.success) {
            this.tableId = res.result[0].id
          } else {
            this.$message.error(res.message)
          }
        })
        .catch((err) => {
          this.$message.error(err.message)
        })
    },

    // 调用申请数据
    desformApplyHandleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true
          let timeList = this.dataTime.filter((obj) =>
            this.itemIndex.some((obj1) => obj1 == obj.key)
          )
          let times = ''
          for (let i = 0; i < timeList.length; i++) {
            times += timeList[i].time + ','
          }
          if (times.endsWith(',')) {
            times = times.substring(0, times.length - 1)
          }
          const params = {
            userId: this.userInfo().id,
            useDate: this.form.useDate,
            roomId: this.form.roomId,
            usedTimes: times,
            remark: this.form.remark,
            num: this.form.num,
          }
          console.log('申请数据', params)
          postAction(this.url.optPre + this.tableId, params).then((res) => {
            if (res.success) {
              this.submitProcess(res.result)
            } else {
              this.loading = false
              this.$message.warning(res.message)
            }
          })
        }
      })
    },

    submitProcess(data) {
      var param = {
        tableId: data.tableId,
        formDataId: data.formDataId,
        formUrl: 'modules/bpm/task/form/OnlineFormDetail',
        formUrlMobile: 'online/OnlineDetailForm',
      }
      getAction(this.url.startProcess, param).then((res) => {
        if (res.success) {
          this.loading = false
          this.$message.success('会议室申请提交成功')
          this.$router.go(-1)
        } else {
          this.loading = false
          this.$message.warning(res.message)
        }
      })
    },

    changeTab(index) {
      this.tabIndex = index
    },
  },
}
</script>
<style lang="less" scoped>
.main-body {
  background: #f1f1f1;
  padding: 50px 0;
  .content {
    width: 1200px;
    background: #fff;
    margin: auto;

    .title {
      border-bottom: 1px solid #e5e5e5;
      .horn-title-style {
        cursor: pointer;
        padding: 0 30px;
        height: 55px;
        line-height: 55px;
        position: relative;
        font-size: 22px;
        font-weight: 400;
        color: #333;
        text-align: center;

        &::before {
          content: '';
          width: 0;
          height: 0;
          border: 10px solid transparent;
          top: 100%;
          left: 50%;
          position: absolute;
          margin-left: -12px;
        }
      }

      .active {
        background-color: #1285d6;
        color: #fff;
        &::before {
          border-top-color: rgb(18, 133, 214);
        }
      }
    }
  }

  .form-content {
    padding: 30px 50px;
    width: 1200px;
    min-height: 600px;
    background: #fff;
    margin: auto;

    .item {
      width: 110px;
      border: 1px solid #ddd;
      cursor: pointer;
      margin: 5px;
      text-align: center;

      &.active {
        border-color: #1890ff;
        background: #1890ff;
        color: #fff;
      }

      &.time-disable {
        border-color: #ff4d4f;
        background: #ff4d4f;
        color: #fff;
      }
    }

    /deep/ .tips {
      label::before {
        display: inline-block;
        margin-right: 4px;
        color: #f5222d;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
      }
    }
  }

  .footer {
    width: 1200px;
    margin: 0 auto;
    margin-top: 25px;
    background: #fff;
    text-align: center;
    padding: 20px 0;

    .btn-style {
      height: 40px;
      line-height: 40px;
      padding: 0 30px;
      margin: 0 50px;
    }
  }

  .description {
    width: 1200px;
    min-height: 600px;
    background: #fff;
    margin: 0 auto;
    padding: 30px;
  }
}
</style>
