import { render, staticRenderFns } from "./DesformCard.vue?vue&type=template&id=2be3fbb2&scoped=true&"
import script from "./DesformCard.vue?vue&type=script&lang=js&"
export * from "./DesformCard.vue?vue&type=script&lang=js&"
import style0 from "./DesformCard.vue?vue&type=style&index=0&id=2be3fbb2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2be3fbb2",
  null
  
)

export default component.exports