<template>
  <div class="card-wrap">
    <ul class="flex-wrap flex-wrap-wrap">
      <li v-for="(item, index) in grids" :key="index" @click="handClick(item)">
        <img :src="item.icon" alt="" />
        <div>{{ item.name }}</div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'desformCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    grids: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headStyle: {
        backgroundColor: 'rgb(234, 234, 234)',
      },
      bodyStyle: {
        minHeight: '240px',
      },
      isShowMore: false,
    }
  },
  methods: {
    handClick(item) {
      this.$emit('handClick', item)
    },
  },
}
</script>
<style lang="less" scoped>
.card-wrap {
  padding: 5px 0 29px 0;
  ul {
    li {
      width: 25%;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      margin-top: 40px;
      cursor: pointer;
      img {
        width: 90px;
        height: 90px;
        border-radius: 50%;
      }

      > div {
        margin-top: 10px;
      }
    }
  }
}
</style>
